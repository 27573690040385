import "../../editorStyleSheet.css"
import Switch from 'react-switch';
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from 'react';
import {getScriptDirection} from "../../../../utlis/generalUtils";
import {useSoftUIController} from "../../../../context";
import {LIBRARIAN_ROLE, LIBRARY_ADMIN_ROLE} from "../../../../utlis/constant";
import SaveButton from "../editorToolbarComponents/SaveButton";
import CircularProgress from '@mui/material/CircularProgress';
import AudioPlayer from "../wavesurfer/AudioPlayer";

const DigitizationContentComponent = ({
    containerWidth, configWidth, pageNumber, updateConfigWidth,
    pages, sourceLanguage, targetLanguage, addOrUpdateText,
    updatedText, handleSave
}) => {
    const [controller] = useSoftUIController();
    const {role} = controller;
    const [displayExtractedText, setDisplayExtractedText] = useState(true);
    const [textContent, setTextContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const noContentTags = ["No OCR is available.", "No Translation is available.", "NO-TEXT due to harmful content."];

    const checkPageType = (currentPage) => {
        switch (currentPage.type) {
            case "NO_TEXT":
                setTextContent("No TEXT.");
                break;
            case "HARMFUL_CONTENT":
                setTextContent(displayExtractedText ? currentPage.content ?? "No OCR is available." : "NO-TEXT due to harmful content.");
                break;
            case "TEXT":
                setTextContent(displayExtractedText ? currentPage.content ?? "No OCR is available." : currentPage.translated_text ?? "No Translation is available.");
                break;
            default:
                setTextContent(displayExtractedText ? currentPage.content ?? "No OCR is available." : currentPage.translated_text ?? "No Translation is available.");
        }
    }

    useEffect(() => {
        const currentPage = pages[pageNumber - 1];
        if (currentPage) {
            checkPageType(currentPage);
            setAudioUrl(currentPage.audio_path);
        }
    }, [pageNumber, displayExtractedText, pages]);

    const handleSwitchChange = () => {
        setDisplayExtractedText(!displayExtractedText);
    };

    const updateText = (e) => {
        const currentPage = pages[pageNumber - 1];
        if (currentPage.type === 'TEXT' || currentPage.type === null) {
            setTextContent(e.target.value);
            addOrUpdateText(pageNumber, e.target.value, displayExtractedText ? 'content' : 'translated_text');
        }
    }

    const handleContentSectionMouseDown = useCallback((e) => {
        e.preventDefault();
        const initialWidth = e.target.parentElement.offsetWidth;
        const initialDivWidth = e.target.offsetParent.offsetWidth;
        const initialX = e.clientX;

        const handleMouseContentSectionMove = (e) => {
            let newWidth = initialWidth - (e.clientX - initialX);

            if (newWidth < initialDivWidth * 0.2) {
                newWidth = (initialDivWidth * 0.2);
            } else if (newWidth > initialDivWidth * 0.5) {
                newWidth = (initialDivWidth * 0.5);
            }

            const contentSection = document.getElementById("digitizationEditorContentSection");
            if (contentSection && contentSection.offsetWidth !== newWidth) {
                contentSection.style.width = newWidth + 'px';
                updateConfigWidth(newWidth);
            }
            const event = new CustomEvent('configResized');
            window.dispatchEvent(event);
        };

        const handleMouseContentSectionUp = () => {
            document.removeEventListener('mousemove', handleMouseContentSectionMove);
            document.removeEventListener('mouseup', handleMouseContentSectionUp);
        };

        document.addEventListener('mousemove', handleMouseContentSectionMove);
        document.addEventListener('mouseup', handleMouseContentSectionUp);
    }, [updateConfigWidth]);

    const handleSaveWithLoading = () => {
        setLoading(true);
        handleSave()
            .then(() => {
                // success handled in handleSave
            })
            .catch((err) => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="digitizationEditorContentSection" id="digitizationEditorContentSection"
             style={{display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '100vh'}}
        >
            <div className="resize-handle" onMouseDown={handleContentSectionMouseDown}/>
            <div style={{
                borderBottom: '1px solid #e0d8d8b0', padding: "10px", height: "7%", display: "flex",
                flexDirection: "row", justifyContent: "space-between", alignItems: "center", minWidth: "max-content"
            }}>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem",
                    margin: "0 auto"
                }}>
                    <div style={{fontSize: "smaller"}}>
                        Translated Text
                    </div>
                    <div>
                        <Switch
                            checked={displayExtractedText}
                            inputProps={{'aria-label': 'Toggle between original content and translated text'}}
                            onColor="#f00084"
                            offColor="#888"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={10}
                            height={15}
                            width={30}
                            onChange={handleSwitchChange}
                        />
                    </div>
                    <div style={{fontSize: "smaller"}}>
                        Extracted Text
                    </div>
                </div>

                {(role === LIBRARIAN_ROLE || role === LIBRARY_ADMIN_ROLE) && (
                    <SaveButton updatedText={updatedText} handleSave={handleSaveWithLoading} disabled={loading}/>
                )}
            </div>

            <div style={{
                fontWeight: 400,
                paddingTop: "20px",
                display: "flex",
                justifyContent: "center",
                fontSize: 'smaller',
            }}>
                <span>page - {pageNumber}</span>
            </div>

            <div style={{padding: "0px 15px 0px 15px", width: "100%", flexGrow: 1}}>
                {loading && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 10
                    }}>
                        <CircularProgress/>
                    </div>
                )}

                <textarea
                    disabled={loading}
                    onChange={((role === LIBRARIAN_ROLE || role === LIBRARY_ADMIN_ROLE) && !noContentTags.includes(textContent)) ? updateText : undefined}
                    value={textContent}
                    className="digitization-note"
                    style={{
                        borderColor: '#e6e6e7',
                        borderRadius: '4px',
                        fontSize: 'smaller',
                        resize: 'none',
                        width: '100%',
                        padding: "15px",
                        direction: displayExtractedText ? getScriptDirection(sourceLanguage) : getScriptDirection(targetLanguage),
                        height: '100%',
                        boxSizing: 'border-box'
                    }}
                    placeholder="Text content will appear here."
                />
            </div>
            {displayExtractedText && audioUrl && !loading && (
                <AudioPlayer audioUrl={audioUrl}/>
            )}
        </div>
    )
}

DigitizationContentComponent.propTypes = {
    containerWidth: PropTypes.string,
    pageNumber: PropTypes.number,
    configWidth: PropTypes.string,
    updateConfigWidth: PropTypes.func,
    pages: PropTypes.array,
    sourceLanguage: PropTypes.string,
    targetLanguage: PropTypes.string,
    addOrUpdateText: PropTypes.func,
    updatedText: PropTypes.array,
    handleSave: PropTypes.func
};

export default DigitizationContentComponent;
