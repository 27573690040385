import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { FaPlay, FaPause } from 'react-icons/fa';
import WaveSurfer from 'wavesurfer.js';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const AudioPlayer = ({ audioUrl}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const wavesurferRef = useRef(null);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);

    useEffect(() => {
        if (wavesurferRef.current) {
            wavesurferRef.current.destroy();
        }

        const wavesurfer = WaveSurfer.create({
            container: '#waveform',
            waveColor: '#ddd',
            progressColor: '#f00084',
            height: 80,
            barWidth: 2,
            cursorWidth: 1,
        });

        wavesurferRef.current = wavesurfer;

        const loadAudio = async () => {
            setIsReady(false);
            try {
                if (audioUrl) {
                    await wavesurfer.load(audioUrl);
                    setIsReady(true);
                    setIsPlaying(false)
                    wavesurfer.setPlaybackRate(playbackSpeed);
                }
            } catch (error) {
                setIsReady(false)
                setIsPlaying(false)
            }
        };

        loadAudio();

        return () => {
            if (wavesurferRef.current) {
                wavesurferRef.current.destroy();
            }
        };
    }, [audioUrl]);

    useEffect(() => {

        if (wavesurferRef.current) {
            wavesurferRef.current.setPlaybackRate(playbackSpeed);
        }
    }, [playbackSpeed]);

    const handleSpeedChange = (event) => {
        const speed = parseFloat(event.target.value);
        setPlaybackSpeed(speed);
    };

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
        if (!isPlaying) {
            wavesurferRef.current.play();
        } else {
            wavesurferRef.current.pause();
        }
    };

    return (
        <div
            style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                padding: '0 15px',
                maxWidth: '100%',
            }}
        >
            <button
                onClick={handlePlayPause}
                style={{border: 'none', background: 'none', cursor: 'pointer'}}
                disabled={!isReady}
            >
                {isPlaying ? <FaPause size={20}/> : <FaPlay size={20}/>}
            </button>
            <div id="waveform" style={{width: '80%', height: '80px', maxWidth: '100%'}}/>

            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <label htmlFor="speedControl" style={{fontWeight: 'bold', fontSize: '12px'}}>
                    Speed:
                </label>
                <Select
                    id="speedControl"
                    value={playbackSpeed}
                    onChange={handleSpeedChange}
                    sx={{
                        borderRadius: '5px',
                    }}
                    variant="outlined"
                >
                    <MenuItem value={0.5}>0.5x</MenuItem>
                    <MenuItem value={1}>1x</MenuItem>
                    <MenuItem value={1.5}>1.5x</MenuItem>
                    <MenuItem value={2}>2x</MenuItem>
                </Select>
            </div>
        </div>
    );
};

AudioPlayer.propTypes = {
    audioUrl: PropTypes.string,
};

export default AudioPlayer;
