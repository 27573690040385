import React, {useState, useEffect} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {FaDownload} from 'react-icons/fa';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import {getTitleWithoutDate} from "../../utlis/generalUtils";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DocumentDownloadSnackbar = ({documents, handleDownload, deleteFile, onClose}) => {
    const [openSnackbars, setOpenSnackbars] = useState({});

    useEffect(() => {
        if (documents.length > 0) {
            const initialSnackbarsState = documents.reduce((acc, doc) => ({
                ...acc,
                [doc.title]: true
            }), {});
            setOpenSnackbars(initialSnackbarsState);
        }
    }, [documents]);

    const handleClose = (docTitle, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbars((prev) => ({...prev, [docTitle]: false}));
    };

    const handleDownloadAndClose = (doc) => {
        handleDownload(doc);
        setOpenSnackbars((prev) => ({...prev, [doc.title]: false}));
    };

    const handleRemoveAndClose = (doc) => {
        if (doc.isReady) {
            deleteFile(doc.title);
        } else {
            onClose(doc.title)
        }
        setOpenSnackbars((prev) => ({...prev, [doc.title]: false}));
    };

    return (
        <>
            {documents.length > 0 && documents.map((doc, index) => (
                <Snackbar
                    key={doc.title}
                    open={openSnackbars[doc.title] || false}
                    onClose={(event, reason) => handleClose(doc.title, reason)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    style={{bottom: `${index * 60}px`, right: '20px'}}
                >
                    <Alert
                        onClose={() => handleRemoveAndClose(doc)}
                        severity="info"
                        sx={{
                            borderColor: 'transparent',
                            width: '100%',
                            backgroundColor: '#e9ecef',
                            color: '#67748e',
                            overflow: 'hidden !important',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{marginRight: !doc.isReady  ? '30px' : '5px'}}> {getTitleWithoutDate(doc.title)}
                            {!doc.isReady ? <CircularProgress
                                size={16}
                                thickness={3}
                                style={{
                                    marginLeft: 20,
                                    marginTop: 7,
                                    color: '#169918',
                                    position: 'absolute',
                                }}
                            /> : <button
                                onClick={() => handleDownloadAndClose(doc)}
                                style={{
                                    marginLeft: 20,
                                    background: 'transparent',
                                    borderColor: 'transparent',
                                    cursor: 'pointer',
                                    color: '#67748e'
                                }}
                            >
                                <FaDownload/>
                            </button>
                            }
                        </div>

                    </Alert>

                </Snackbar>
            ))}
        </>
    );
};

DocumentDownloadSnackbar.propTypes = {
    documents: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            isReady: PropTypes.bool.isRequired,
        })
    ).isRequired,
    handleDownload: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
};

export default DocumentDownloadSnackbar;