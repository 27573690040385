import { Navigate } from "react-router-dom";

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password";
import ResetNewPassword from "layouts/authentication/confirmResetPassword";
import PrivateRoute from "layouts/authentication/PrivateRoute";
import { FaUniversity } from "react-icons/fa";

// Soft UI Dashboard React icons
import Shop from "reusableComponents/Icons/Shop";
import Office from "reusableComponents/Icons/Office";
import Document from "reusableComponents/Icons/Document";
import SpaceShip from "reusableComponents/Icons/SpaceShip";
import CustomerSupport from "reusableComponents/Icons/CustomerSupport";
import Departments from "./mainComponents/departments/Departments";
import Universities from "mainComponents/universities /Universities";
import Librarians from "./mainComponents/librarians/Librarians";
import publications from "./layouts/publications";
import { HiMiniUsers } from "react-icons/hi2";
import ResearcherManagement from "./mainComponents/researcherManagement/ResearcherManagement";
import confirmation from "./layouts/authentication/confirmation";
import Report from "./mainComponents/report/Report";
import MyPublications from "layouts/mydocuments";
import AgentDigitizations from "layouts/agent-publications";
import PaymentList from "./mainComponents/payment/PaymentList";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { BsFiletypeJson } from "react-icons/bs";
import SwaggerDoc from "swagger/swaggerDocs";
import FreeAgents from "./mainComponents/freeAgents/FreeAgents";
import DigitizationEditor from "./mainComponents/yai4eduEditor/DigitizationEditor";
import ErrorPages from "./reusableComponents/ErrorPages/ErrorPages";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['super-admin', 'librarian', 'researcher', 'library-admin', 'free-agent']} component={Dashboard} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Departments List",
    key: "departments-list",
    route: "/departments-list",
    icon: <Office size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['library-admin']} component={Departments} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Universities List",
    key: "universities-list",
    route: "/universities-list",
    icon: <FaUniversity size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['super-admin']} component={Universities} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Librarian Admins Management",
    key: "librarian-admins",
    route: "librarian-admins",
    icon: <Office size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['super-admin']} component={Librarians} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Free-agents List",
    key: "free-agents",
    route: "/free-agents",
    icon: <Office size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['super-admin']} component={FreeAgents} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Agent Digitizations",
    key: "agent-digitizations",
    route: "agent-digitizations",
    icon: <Office size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['super-admin']} component={AgentDigitizations} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Librarian Management",
    key: "librarian-management",
    route: "librarian-management",
    icon: <Office size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['library-admin']} component={Librarians} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['super-admin', 'librarian', 'library-admin', 'researcher', 'free-agent']} component={Profile} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <PrivateRoute publicRoute={true} component={SignIn} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <SpaceShip size="12px" />,
    component: <PrivateRoute publicRoute={true} component={SignUp} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reset Password Form",
    key: "reset-password-form",
    route: "/authentication/reset-password",
    icon: <SpaceShip size="12px" />,
    component: <PrivateRoute publicRoute={true} component={ResetPassword} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Confirm Reset Password",
    key: "confirm-reset-password",
    route: "/authentication/confirm-reset-password/:token",
    icon: <SpaceShip size="12px" />,
    component: <PrivateRoute publicRoute={true} component={ResetNewPassword} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Researcher Management",
    key: "researcher-management",
    route: "/researcher-management",
    icon: <HiMiniUsers size="12px" />,
    component: (
        <PrivateRoute allowedRoles={['librarian']} component={ResearcherManagement} />
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Documents",
    key: "documents",
    route: "/documents",
    component: <PrivateRoute allowedRoles={['super-admin', 'librarian', 'researcher', 'library-admin', 'free-agent']} component={publications} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Confirmation",
    key: "confirmation",
    route: "/authentication/confirmation",
    component: <PrivateRoute publicRoute={true} component={confirmation} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    route: "/report",
    component: <PrivateRoute allowedRoles={['super-admin','librarian', 'library-admin']} component={Report} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "My Documents",
    key: "my-documents",
    route: "/my-documents",
    icon: <Document size="12px" />,
    component: <PrivateRoute allowedRoles={['librarian', 'researcher']} component={MyPublications} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Document",
    key: "document",
    route: "/document",
    icon: <Document size="12px" />,
    component: <PrivateRoute allowedRoles={['super-admin', 'librarian', 'researcher', 'library-admin', 'free-agent']} component={DigitizationEditor} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Payment list",
    key: "payment-list",
    route: "/payment-list",
    icon: <FaFileInvoiceDollar size="12px"/>,
    component: <PrivateRoute allowedRoles={['super-admin']} component={PaymentList} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Invoices",
    key: "invoices",
    route: "/invoices",
    icon: <FaFileInvoiceDollar size="12px"/>,
    component: <PrivateRoute allowedRoles={['free-agent']} component={PaymentList} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Error",
    key: "error",
    route: "/error-page",
    icon: <FaFileInvoiceDollar size="12px"/>,
    component: <PrivateRoute publicRoute={true} component={ErrorPages} />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "API Documentation",
    key: "api-docs",
    route: "/api-docs",
    icon: <BsFiletypeJson size="12px"/>,
    component: (
        <PrivateRoute allowedRoles={['super-admin', 'library-admin', 'librarian']} component={SwaggerDoc} />
    ),
    noCollapse: true,
  },
];

export default routes;
