import React, {useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Box, Chip} from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SuiButton from "components/SoftButton";
import {MdRssFeed} from "react-icons/md";
import {IoIosAdd} from "react-icons/io";
import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "reusableComponents/Navbars/DashboardNavbar";
import Footer from "reusableComponents/Footer";
import CardComponent from "reusableComponents/Tables/Card";
import {createArticle, getDigitizations, getArchivesById} from "axios-client";
import data from '../../constants/languages.json';

import {formatDate, formatDataToUrlQueryParams} from "utlis/generalUtils";
import DigitizeArticleDialog from "./DigitizeArticleDialog";
import {digitize, getSubjects} from "../../axios-client";

import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import {useSoftUIController} from "../../context";
import InputAdornment from "@mui/material/InputAdornment";
import {FREE_AGENT} from "../../utlis/constant";
import FilterList from "./FilterList";

const publications = () => {
    // State declarationss
    const [controller] = useSoftUIController();
    const {role} = controller;
    const rolesAllowed = ['librarian', /*'researcher',*/ 'free-agent'];
    const [rows, setRows] = useState([]);
    const [articleCount, setArticleCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentFilter, setCurrentFilter] = useState("All Fields");
    const [searchText, setSearchText] = useState("");
    const [isDigitizing, setIsDigitizing] = useState(false);
    const [openDigitizerDialog, setOpenDigitizerDialog] = useState(false);
    const [notification, setNotification] = useState({
        show: false,
        snackbarMessage: "",
        severity: "",

    });
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
        Title: "",
        Author: "",
        Date: "",
        SubjectsAndKeywords: "",
        subjects: "",
        languages: "",
        all_fields: "",
    });

    const [languages, setLanguages] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const [searchSubjectTerm, setSearchSubjectTerm] = useState("");
    const [selectedSubjects, setSelectedSubjects] = useState([]);

    const [searchLanguageTerm, setSearchLanguageTerm] = useState("");
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const [subjectCollapsed, setSubjectCollapsed] = useState(true);
    const [languageCollapsed, setLanguageCollapsed] = useState(true);
    const [filterCollapsed, setFilterCollapsed] = useState(true);

    useEffect(() => {
        setLanguages(data);
        getSubjects()
            .then(res => {
                setSubjects(res.data);
            })
            .catch(error => {
                console.error('Error fetching subjects:', error);
            });
    }, []);

    // Effect hook to fetch data when filterData changes
    useEffect(() => {
        refreshList()
    }, [filterData]);

    // Data fetching function
    const refreshList = () => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        fetchData(`?${queryParams}`);
    }
    const fetchData = (params) => {
        setLoading(true);
        setError(null);

        getDigitizations(params)
            .then((res) => {
                const formattedRows = res.data.data
                    ? res.data.data.map((archive) => ({
                        id: archive.id,
                        picture_path: archive.article.picture_path,
                        title: archive.article.title,
                        subjects: archive.article.subjects,
                        author: archive.article.author,
                        summary: archive.summary,
                        publishedAt: archive.article.publication_date,
                        status: archive.status,
                        services_data: archive.services_data,
                        createdAt: archive.article.created_at,
                        origin: archive.article.origin,
                        bookmarking_path: archive.article.bookmarking_path,
                        articleId: archive.article.id,
                        cost: archive.article.cost,
                        source_language: archive.article.source_language,
                        target_language: archive.article.target_language,
                        digitizerId:archive.article.user_id,
                        has_audio_option:archive.article.has_audio_option
                    }))
                    : [];
                setArticleCount(res.data.count);
                setRows(formattedRows);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching archives:", error);
                setError("Failed to load data. Please try again.");
                setLoading(false);
            });
    };

    const getArchivesBySelectedDocument = async (id) => {
        try {
            const res = await getArchivesById(id);
            let data = res.data.data;
            return {
                details: {
                    Digitization_date: data.article.created_at,
                    Number_of_pages: data.article.page_count,
                    Document_ID: data.id,
                },
                title: data.article.title,
                author: data.article.author,
                filename: data.article.filename,
                extension: data.article.extension,
                publishedAt: data.article.publication_date,
                picture_path: data.article.picture_path,
                ocr_text: data.ocr_text,
                translated_text: data.translated_text,
                summary: data.summary,
                bullet_points_summary: data.bullet_points_summary,
                description: data.description,
                bookmarking_path: data.bookmarking_path,
                cost: data.article.cost,
            };
        } catch (error) {
            console.error("Error fetching archives by id:", error);
        }
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    // Handle Enter key press in search input
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearchSubmit();
        }
    };

    // Handle search submission
    const handleSearchSubmit = () => {
        // Reset all filters except the current one
        const updatedFilterData = {...filterData};

        // Map the current filter to the corresponding key in filterData
        const filterKeyMap = {
            "All Fields": "all_fields",
            Title: "title",
            Author: "author",
            Date: "date",
            "Subjects And Keywords": "subjectsAndkeywords",
        };

        const filterKey = filterKeyMap[currentFilter] || "all_fields";

        // Set the current filter value
        updatedFilterData[filterKey] = searchText.trim();

        setFilterData(updatedFilterData);
    };

    // Handle filter selection change
    const handleFilterChange = (event) => {
        setCurrentFilter(event.target.value);
    };

    // Pagination handlers
    const handlePageChange = (newPage) => {
        setFilterData((prev) => ({...prev, page: newPage}));
    };

    const handleRowsPerPageChange = (newSize) => {
        setFilterData((prev) => ({...prev, size: newSize, page: 1}));
    };

    // Functions for digitizing articles
    const handleOpenDialog = () => {
        setOpenDigitizerDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDigitizerDialog(false);
    };

    const handleDigitizeArticle = (data) => {
        setIsDigitizing(true);
        digitize(data)
            .then((response) => {
                setNotification({
                    show: true,
                    snackbarMessage: "Article created successfully!",
                    severity: "success",
                });
                setOpenDigitizerDialog(false);
                setIsDigitizing(false);
                setFilterData({...filterData, page: 1});
            })
            .catch((error) => {
                setIsDigitizing(false);
                setNotification({
                    show: true,
                    snackbarMessage: "Failed to digitize the document. Please try again!",
                    severity: "error",
                });
            });
    };

    const handleCreateArticle = (data) => {
        setIsDigitizing(true);
        createArticle(data)
            .then((response) => {
                setNotification({
                    show: true,
                    snackbarMessage: "Article created successfully!",
                    severity: "success",
                });
                setOpenDigitizerDialog(false);
                setIsDigitizing(false);
                setFilterData({...filterData, page: 1});
            })
            .catch((error) => {
                setIsDigitizing(false);
                setNotification({
                    show: true,
                    snackbarMessage: "Failed to digitize the document. Please try again!",
                    severity: "error",
                });
            });
    };

    const handleCheckboxSubjectChange = (label) => {
        let updatedItems
        if (selectedSubjects.includes(label)) {
            setSelectedSubjects(selectedSubjects.filter((item) => item !== label));
            updatedItems = selectedSubjects.filter((item) => item !== label)
        } else {
            setSelectedSubjects([...selectedSubjects, label]);
            updatedItems = [...selectedSubjects, label]

        }

        setFilterData({...filterData, subjects: updatedItems.join(', ')});

    };

    const handleCheckboxLanguageChange = (label) => {
        let updatedItems
        if (selectedLanguages.includes(label)) {
            updatedItems = selectedLanguages.filter((item) => item !== label)
            setSelectedLanguages(selectedLanguages.filter((item) => item !== label));
        } else {
            setSelectedLanguages([...selectedLanguages, label]);
            updatedItems = [...selectedLanguages, label]
        }

        setFilterData({...filterData, languages: updatedItems.join(', ')});

    };

    const handleSubjectCollapseToggle = () => {
        setSubjectCollapsed(!subjectCollapsed);
    };

    const handleLanguageCollapseToggle = () => {
        setLanguageCollapsed(!languageCollapsed);
    };

    const handleFilterCollapseToggle = () => {
        setFilterCollapsed(!filterCollapsed);
    };

    const handleClearAll = () => {
        setSelectedSubjects([]);
        setSelectedLanguages([]);
        setFilterData({...filterData, subjects: "", languages: ""})
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox py={2} style={{minHeight: "630px"}}>
                <SoftBox mb={2}>
                    {/* Search and Filter Section */}
                    <SoftBox mb={0.5} sx={{marginRight: '8px', marginLeft: '8px', paddingTop: "30px"}}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={12} md={12}>
                                <Box display="flex" alignItems="center">
                                    {/* Search Input */}
                                    <Box sx={{flexGrow: 1}}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search..."
                                            value={searchText}
                                            onChange={handleSearchChange}
                                            onKeyDown={handleKeyDown}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" sx={{marginLeft: "auto"}}>
                                                        <IconButton onClick={handleSearchSubmit} color="primary">
                                                            <SearchIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>

                                    {/* 'Filter By' Dropdown */}
                                    <Box sx={{ml: 2}}>
                                        <Select
                                            value={currentFilter}
                                            onChange={handleFilterChange}
                                            displayEmpty
                                            variant="outlined"
                                            inputProps={{"aria-label": "Filter By"}}
                                            style={{minWidth: 150}}
                                        >
                                            <MenuItem value="All Fields">All Fields</MenuItem>
                                            <MenuItem value="Title">Title</MenuItem>
                                            <MenuItem value="Author">Author</MenuItem>
                                            <MenuItem value="Date">Date</MenuItem>
                                            <MenuItem value="Subjects And Keywords">
                                                Subjects And Keywords
                                            </MenuItem>
                                        </Select>
                                    </Box>
                                    {/* Search Button */}
                                    <Box sx={{ml: 1}}>
                                        <IconButton onClick={handleSearchSubmit} color="primary">
                                            <SearchIcon/>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </SoftBox>

                    {/* Results Header and Action Button */}
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        style={{paddingTop: "16px", paddingBottom: "16px"}}
                    >
                        <SoftTypography variant="h6">
                            {articleCount} results <MdRssFeed/>
                        </SoftTypography>
                        {rolesAllowed.includes(role) && <SuiButton
                            variant="gradient"
                            color="primary"
                            size="small"
                            onClick={handleOpenDialog}
                        >
                            <IoIosAdd/> Digitize new document
                        </SuiButton>}
                    </SoftBox>

                    {/* Error Message */}
                    {error && (
                        <SoftBox mb={2}>
                            <SoftTypography color="error">{error}</SoftTypography>
                        </SoftBox>
                    )}

                    <div style={{display: 'flex'}}>

                        <FilterList
                            filterCollapsed={filterCollapsed}
                            handleFilterCollapseToggle={handleFilterCollapseToggle}
                            selectedSubjects={selectedSubjects}
                            selectedLanguages={selectedLanguages}
                            handleCheckboxSubjectChange={handleCheckboxSubjectChange}
                            handleCheckboxLanguageChange={handleCheckboxLanguageChange}
                            handleClearAll={handleClearAll}
                            searchSubjectTerm={searchSubjectTerm}
                            setSearchSubjectTerm={setSearchSubjectTerm}
                            searchLanguageTerm={searchLanguageTerm}
                            setSearchLanguageTerm={setSearchLanguageTerm}
                            subjects={subjects}
                            languages={languages}
                            subjectCollapsed={subjectCollapsed}
                            handleSubjectCollapseToggle={handleSubjectCollapseToggle}
                            languageCollapsed={languageCollapsed}
                            handleLanguageCollapseToggle={handleLanguageCollapseToggle}
                        />

                        {loading ? (
                            <SoftBox
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="200px"
                                width="100%"
                            >
                                <SoftTypography>Loading...</SoftTypography>
                            </SoftBox>
                        ) : (
                            <SoftBox style={{width: filterCollapsed ? '80%' : '100%'}}>
                                <Grid>
                                    <CardComponent
                                        data={rows}
                                        count={articleCount}
                                        size={filterData.size}
                                        page={filterData.page}
                                        handlePageChange={handlePageChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        loadingData={loading}
                                        getArchivesBySelectedDocument={getArchivesBySelectedDocument}
                                        setNotification={setNotification}
                                        searchText={searchText}
                                        refreshList={refreshList}
                                    />
                                </Grid>
                            </SoftBox>
                        )}
                    </div>

                </SoftBox>
            </SoftBox>
            <Footer/>

            {/* Digitize Article Dialog */}
            {openDigitizerDialog && (
                <DigitizeArticleDialog
                    open={openDigitizerDialog}
                    isDigitizing={isDigitizing}
                    onClose={handleCloseDialog}
                    handleDigitizeArticle={role == FREE_AGENT ? handleCreateArticle : handleDigitizeArticle}
                />
            )}

            {/* Snackbar Notification */}
            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />
        </DashboardLayout>
    );
};

export default publications;
